import BaseService, { APIResponse } from "./BaseService";

export type AbbonamentoResponse = {
  url: string
};
export default class Abbonamento extends BaseService {

    static async getAbbonamentoPage(idStruttura:number,quantita:number): Promise<APIResponse<string>> {
        return await this.perform<string>({
            url: "operatore/pagamento/abbonamento/"+idStruttura,
          params:{"quantita": quantita,  "fonte":"Merchant"},
            method: "GET",
        });
    }

}
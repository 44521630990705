













































import {Component, Vue} from "vue-property-decorator";
import {DateTime} from "luxon";
import AbbonamentiService from "@/services/AbbonamentiService";

import Toolbar from "@/components/Toolbar.vue";

import StruttureService, {Struttura, StruttureResponse} from "@/services/StruttureService";
@Component({
  components: {
    Toolbar,
  },
})
export default class Subscriptions extends Vue {

  public strutture:StruttureResponse = [];
  public struttura:Struttura | any = {};
  public  mesi:number=1;
  public dialogFormVisible:boolean = false;
  public  RinnovaAbbonamento(index:number){
    this.struttura = this.strutture[index].struttura.info;
    this.dialogFormVisible=true;
  }
  public async RiceviStrutture(){
    const response = await StruttureService.getStrutture();
    if(response.error)
      return
    for(let struttura of response.data){
      struttura.struttura.info.subscription_period_end = DateTime.fromISO(struttura.struttura.info.subscription_period_end).toFormat('dd/MM/yyyy')
    }
    console.log(response.data)
    this.strutture = response.data
  }
  public ControlloScadenza(index:number){
   return DateTime.fromFormat(this.strutture[index].struttura.info.subscription_period_end,'dd/MM/yyyy') >= DateTime.now()
  }
  public OpenModal(idPagamento:string){
    if(idPagamento=="1")
      this.$message({
        message: 'Pagamento avvenuto con successo',
        type: 'success'
      });
    else if(idPagamento=="2")
      this.$message.error('Ops, qualcosa è andato storto nel pagamento riprova');
  }
  public  async avviaAbbonamento(){
    const response = await AbbonamentiService.getAbbonamentoPage(this.struttura.id,this.mesi)
    if(response.error)
      return;
    window.location.href = response.data;
  }
  //mounted
  mounted() {
    if(this.$route.params.id)
     this.OpenModal(this.$route.params.id);
    this.RiceviStrutture();
  }
}
